.work {
  @include clearfix;
  align-items: flex-start;
  box-sizing: border-box;
  color: $black;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-flow: flex-start;
  flex-wrap: wrap;
  padding: 3.6rem 0;
  width: 100%;

  .whole {
    flex-basis: 100%;
    max-width: 100%;
  }

  .half {
    flex-basis: 50%;
    max-width: 50%;
  }

  p {
    line-height: 1.5em;
    margin: 0 0 1em;

    &.intro {
      color: $white;
      font: 3.6rem/1em $font-stack;
      font-weight: 100;
      margin-bottom: 3.6rem;
    }
  }
}

.case-study {
  margin: 0;
  min-height: 285px;
  padding: 1.5rem 1.5rem 0;
  transition: all 240ms linear;
}

.stage10 {
  background-color: $stage10;

  .pagination-web {
    text-align: center;
    transform: translate3d(0, 0, 0);
    transition: 300ms;
    z-index: 10;
  }

  .swiper-pagination-bullet {
    background: darken($stage10, 50%);
    border: 0;
    transition: all 240ms linear;
  }

  .swiper-pagination-bullet-active {
    background: $white;
    opacity: 1;
  }

  .cd-handle.draggable {
    /* change background color when element is active */
    background-color: $stage10;

  }
}

.sfu {
  background-color: $sfu;

  .swiper-pagination-bullet {
    background: darken($sfu, 50%);
    border: 0;
    transition: all 240ms linear;
  }

  .swiper-pagination-bullet-active {
    background: $white;
    opacity: 1;
  }

  .cd-handle.draggable {
    /* change background color when element is active */
    background-color: $sfu;

  }
}

.freelance {
  background-color: $freelance;

  .pagination-nvbc,
  .pagination-ac,
  .pagination-hpac {
    text-align: center;
    transform: translate3d(0, 0, 0);
    transition: 300ms;
    z-index: 10;
  }

  .swiper-pagination-bullet {
    background: darken($freelance, 50%);
    border: 0;
    transition: all 240ms linear;
  }

  .swiper-pagination-bullet-active {
    background: $white;
    opacity: 1;
  }
}

img {
  display: block;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  vertical-align: bottom;
  width: 100%;
}
