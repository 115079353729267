.contact {
  @include clearfix;
  align-items: flex-start;
  background-color: $grey;
  box-shadow: inset 0 5px 0 $brand-primary;
  color: $white;
  display: flex;
  flex-direction: row;
  flex-flow: flex-start;
  padding: 3.6rem 0;
  width: 100%;

  h2 {
    color: $black;
    font-weight: bold;
  }

  p {
    color: $black;
    font-size: 3.6rem;
    font-weight: 100;
  }

  a {
    border-bottom: 2px solid $white;
    color: $brand-primary;
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
    transition: border-bottom-color 240ms linear;

    &:hover {
      border-bottom-color: $brand-primary;
    }
  }
}
