h1 { 
  color: $white;
  font-family: $font-stack;
  margin: 0 0 1rem;
}

h2 {
  color: $white;
  font: bold 3.6rem/1.5em $font-stack;
  margin: 0;

  &.rule {
    border-top: 1px dotted rgba(220, 220, 200, .6);
    padding-top: 15px;
  }
}

h3 {
  color: $white;
  font-size: 3rem;
  font-weight: normal;
  line-height: 1.25em;
  margin: 0;
}

h4 {
  color: rgba($white, .25);
  font-size: 1.2rem;
  line-height: 1.25em;
  font-weight: bold;
  margin: 0 0 1em;
}

p {
  color: $white;
  font: 1.8rem/1.5em $font-stack;
  font-weight: 100;
  margin: 0 0 1em;
}

a {
  color: $white;
  font-family: $font-stack;
  font-size: 1em;
  text-decoration: underline;
}

figcaption {
  color: $white;
  font-size: 1.2em;
  text-align: center;
}

.intro {
  font-size: 3.6rem;
  font-weight: 100;
}
