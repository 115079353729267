.btn,
button {
  background: transparent;
  border: 2px solid $white;
  border-radius: 3px;
  box-sizing: border-box;
  color: $white;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0 0 1.5rem;
  padding: .6rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 240ms ease;

  &:hover {
    background: $white;
    border-color: $white;
    color: $brand-primary;
  }
}
