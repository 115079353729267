//Breakpoints
$xsmall: 320px;
$small: 768px;
$medium: 920px;
$large:	1100px;
$xlarge: $large;

//Grid Setting
$grid-columns: 12;
$grid-half: $grid-columns/2;
$grid-third: $grid-columns/3;
$grid-quarter: $grid-columns/4;

$base-layout: (
  container: auto,
  columns: 4,
  gutters: .25,
  gutter-position: inside,
  math: fluid,
  output: isolate,
);

$small-layout: (
  container: auto,
  columns: 4,
  gutters: .25,
  gutter-position: inside,
  math: fluid,
  output: isolate,
);

//Container
$container-small: layout(auto $grid-third .25 inside fluid isolate);
$container-medium: layout($medium $grid-half .25 after fluid isolate);
$container-large: layout($large $grid-columns .25 after fluid isolate);
$container-xlarge: layout($xlarge $grid-columns .25 after fluid isolate);


//Colours
$black: #000;
$white: #fff;
$brand-primary: #0081cb;
$brand-secondary: #4e008b;
$grey: #dad8d6;

$stage10: #ff5000;
$sfu: #f6323e;
$freelance: #00a887;

//Fonts

$font-stack: "GothamRndSSm-Book", sans-serif;
