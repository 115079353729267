.about {
  @include clearfix;
  align-items: flex-start;
  background-color: $brand-primary;
  color: $white;
  display: flex;
  flex-direction: row;
  flex-flow: flex-start;
  padding: 3.6rem 0;
  width: 100%;

  h2 {
    font-size: 3.6rem;
    line-height: 3.6rem;
    font-weight: bold;
  }

  p {
    line-height: 1.5em;
    margin: 0 0 1em;

    &.intro {
      color: $white;
      font: 3.6rem/1em $font-stack;
      font-weight: 100;
      margin: 0;
    }
  }
}
