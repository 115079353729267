header {
  display: block;
}

.logo {
  display: block;

  svg {
    fill: $brand-primary;
    float: left;
    height: 3rem;
    margin-right: 1rem;
    transition: all .5s ease;
    width: auto;
  }

  h1 {
    float: left;
    font-size: 2rem;
    margin: 0;

    a {
      color: $brand-primary;
      font-weight: normal;
      text-decoration: none;
    }
  }
}
