html {
  background-size: cover;
  box-sizing: border-box;
  font-family: $font-stack;
  font-size: 62.5%;
}

*, 
*:before, 
*:after {
  box-sizing: inherit;
}

body {
  background-color: $white;
  height: 100vh;
}

figure {
  margin: 0 0 3.6rem;

  .swiper-slide > & {
    margin: 0;
  }
}

.l-pad-bottom {
  margin-bottom: 1.8rem;
}
