.cd-image-container {
  margin: 0 auto 1.8rem;
  max-width: 1100px;
  position: relative;
  width: 100%;
}

.cd-image-container img {
  display: block;
}

.cd-image-label {
  position: absolute;
  bottom: 0;
  right: 0;
  color: $white;
  padding: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0;
  transform: translateY(20px);
  transition: transform 0.3s 0.7s, opacity 0.3s 0.7s;
}

.cd-image-label.is-hidden {
  visibility: hidden;
}
.is-visible .cd-image-label {
  opacity: 1;
  transform: translateY(0);
}

.cd-resize-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  overflow: hidden;
  /* Force Hardware Acceleration in WebKit */
  transform: translateZ(0);
  backface-visibility: hidden;
}
.cd-resize-img img {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 100%;
  width: auto;
  max-width: none;
}
.cd-resize-img .cd-image-label {
  right: auto;
  left: 0;
}
.is-visible .cd-resize-img {
  width: 50%;
  /* bounce in animation of the modified image */
  -webkit-animation: cd-bounce-in 0.7s;
  -moz-animation: cd-bounce-in 0.7s;
  animation: cd-bounce-in 0.7s;
}

@-webkit-keyframes cd-bounce-in {
  0% {
    width: 0;
  }
  60% {
    width: 55%;
  }
  100% {
    width: 50%;
  }
}
@-moz-keyframes cd-bounce-in {
  0% {
    width: 0;
  }
  60% {
    width: 55%;
  }
  100% {
    width: 50%;
  }
}
@keyframes cd-bounce-in {
  0% {
    width: 0;
  }
  60% {
    width: 55%;
  }
  100% {
    width: 50%;
  }
}
.cd-handle {
  position: absolute;
  height: 44px;
  width: 44px;
  /* center the element */
  left: 50%;
  top: 50%;
  margin-left: -22px;
  margin-top: -22px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0) url("../img/cd-arrows.svg") no-repeat center center;
  border: 2px solid $white;
  cursor: move;
  opacity: 0;
  transform: translate3d(0, 0, 0) scale(0);
}
.cd-handle.draggable {
  /* change background color when element is active */
  background-color: opacify($white, .8);
}
.is-visible .cd-handle {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(1);
  transition: transform 0.3s 0.7s, opacity 0s 0.7s;
}
